import { useState } from 'react';
import { useParams } from "react-router-dom";
import Body from "../components/body";
import Book from "../components/books";


export default function CategoriePage({addToFavoris, favorisItems, cartItems, addToCart}) {
    let { categorie } = useParams();
    const [url] = useState(`/categorie_books/${categorie}`);
    
    return (
        <Body>
            <section className='section'> 
                <h3 className='section-title'>Catégorie : {categorie}</h3>
                <Book addToFavoris={addToFavoris} favorisItems={favorisItems} url={url} cartItems={cartItems} addToCart={addToCart} />
            </section>
        </Body>
    );
}



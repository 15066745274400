import { Routes, Route, Navigate } from 'react-router-dom';
import ApiProvider from './contexts/ApiProvider';
import FlashProvider from './contexts/FlashProvider';
import HomePage from './pages/homePage';
import BookPage from './pages/bookPage';
import CartShoppingPage from './pages/cartShoppingPage';
import FavorisPage from './pages/favorisPage';
import SearchPage from './pages/searchPage';
import CategoriePage from './pages/categoriePage';
import Container from 'react-bootstrap/Container';
import Header from './components/header';
import Footer from './components/footer';
import useLocalStorageState from 'use-local-storage-state'


function App() {
  const [cartItems, setCartItems, { removeItem }] = useLocalStorageState('items', {defaultValue: []});
  const [cartItemsNumber, setCartItemsNumber] = useLocalStorageState('counter', {defaultValue: Object.keys(cartItems).length});
  const [favorisItems, setFavorisItems] = useLocalStorageState('favoris', {defaultValue: []});
  const [favorisItemsNumber, setFavorisItemsNumber] = useLocalStorageState('counterFavoris', {defaultValue: Object.keys(favorisItems).length});
  
  // let bookInCart = cartItems.filter(e => e.sku === 'tchoupi-va-au-l');
  
  let total_price = cartItems.reduce((n, {prix, remise}) => n + (parseFloat(prix) - parseFloat(remise)), 0);
  
  function emptyCart() {
      removeItem('items');
      setCartItemsNumber(0);
  }
  
  function addToCart(book){
      console.log('Adding to cart');
      setCartItems([...cartItems, book]);
      
      setCartItemsNumber((cartItemsNumber) => cartItemsNumber + 1);
      console.log(cartItemsNumber);
  }
  
  function removeFromCart(book) {
      let booksToSave = cartItems.filter(e => e.sku !== book.sku);
      console.log(booksToSave);
      setCartItems(booksToSave);
      setCartItemsNumber((cartItemsNumber) => cartItemsNumber - 1);
  }
  
  function emptyFavoris() {
      setFavorisItems([]);
      setFavorisItemsNumber(0);
  }
  
  function addToFavoris(book){
      console.log('Adding to favoris');
      setFavorisItems([...favorisItems, book]);
      
      setFavorisItemsNumber((favorisItemsNumber) => favorisItemsNumber + 1);
  }
  
  function removeFromFavoris(book) {
      let booksToSave = favorisItems.filter(e => e.sku !== book.sku);
      console.log(booksToSave);
      setFavorisItems(booksToSave);
      setFavorisItemsNumber((favorisItemsNumber) => favorisItemsNumber - 1);
  }
  
  return (
    <Container fluid className="App">
        <FlashProvider>
            <ApiProvider>
                <Header cartItemsNumber={cartItemsNumber} favorisItemsNumber={favorisItemsNumber} /> 
                <Routes>
                  <Route path="/" element={<HomePage addToFavoris={addToFavoris} 
                                                     favorisItems={favorisItems}
                                                     cartItems={cartItems} 
                                                     addToCart={addToCart} />} />

                  <Route path="/book/:book_sku" element={<BookPage cartItems={cartItems} addToCart={addToCart} />} />

                  <Route path="/cartshopping" element={<CartShoppingPage cartItems={cartItems} 
                                                                         cartItemsNumber={cartItemsNumber}
                                                                         empty={emptyCart}
                                                                         remove={removeFromCart}
                                                                         total_price={total_price} />} />

                  <Route path="/favoris" 
                    element={<FavorisPage favorisItems={favorisItems} 
                                          favorisItemsNumber={favorisItemsNumber} 
                                          cartItems={cartItems} 
                                          addToCart={addToCart}
                                          empty={emptyFavoris} 
                                          remove={removeFromFavoris} />} />

                  <Route path="/recherche/:search" element={<SearchPage addToFavoris={addToFavoris} 
                                                                        favorisItems={favorisItems} 
                                                                        cartItems={cartItems} 
                                                                        addToCart={addToCart} />} />

                  <Route path="/categorie/:categorie" element={<CategoriePage addToFavoris={addToFavoris}
                                                                              favorisItems={favorisItems} 
                                                                              cartItems={cartItems} 
                                                                              addToCart={addToCart} />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer/>
            </ApiProvider>
        </FlashProvider>
    </Container>
  );
}

export default App;

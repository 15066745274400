import Pagination from 'react-bootstrap/Pagination';

export default function BookPagination({prevUrl, nextUrl, handleNextUrl, handlePrevUrl, pageCount, currentPage, handleSelectedUrl}) {
        
    return (
        <>
            <div className='paginationSMScreen'>
                <Pagination size="sm">
                 {prevUrl 
                 ? <Pagination.Item onClick={handlePrevUrl}>{'< Précédent'}</Pagination.Item>
                     : <Pagination.Item disabled>{'< Précédent'}</Pagination.Item>}

                 {Array.from(Array(pageCount), (e, i) => {
                     return currentPage === i+1 
                         ? <Pagination.Item key={i} active>{i + 1}</Pagination.Item>
                         :<Pagination.Item key={i} onClick={() => handleSelectedUrl(i + 1)}>{i + 1}</Pagination.Item>;
                 })}

                 {nextUrl 
                     ? <Pagination.Item onClick={handleNextUrl} >{'Suivant >'}</Pagination.Item>
                     : <Pagination.Item disabled>{'Suivant >'}</Pagination.Item>}
                </Pagination>
            </div>

            <div className='paginationLGScreen'>
                <br/>
                <Pagination>
                 {prevUrl 
                 ? <Pagination.Item onClick={handlePrevUrl}>{'< Précédent'}</Pagination.Item>
                     : <Pagination.Item disabled>{'< Précédent'}</Pagination.Item>}

                 {Array.from(Array(pageCount), (e, i) => {
                     return currentPage === i+1 
                         ? <Pagination.Item key={i} active>{i + 1}</Pagination.Item>
                         :<Pagination.Item key={i} onClick={() => handleSelectedUrl(i + 1)}>{i + 1}</Pagination.Item>;
                 })}

                 {nextUrl 
                     ? <Pagination.Item onClick={handleNextUrl} >{'Suivant >'}</Pagination.Item>
                     : <Pagination.Item disabled>{'Suivant >'}</Pagination.Item>}
                </Pagination>
            </div>
        </>
    );
}
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import BookRelated from './bookRelated';
import { useParams } from "react-router-dom";
import bookPicture from '../images/world_book_fun.jpg';
import ImageShower from './ImageShower';
import { useApi } from '../contexts/ApiProvider';


function ModalAddBook(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="modal-title">
                Ce livre a bien été ajouté à votre panier
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodyModal">
            {props.book === undefined ?(
                <>
                    <div className='bookCardImageModal'>
                        <Image src={bookPicture}  className="bookImageModal" />
                    </div>
                    <div className="bookInfoModal">
                        <h3 className="bookTitleModal">Title</h3>
                        <p className="bookAuthorModal">Author</p>
                    </div>
                </>
            ):(
                <>                            
                    <div className='bookCardImageModal'>
                        <Image src={props.book.cover}  className="bookImageModal" />
                    </div>
                    <div className="bookInfoModal">
                        <h3 className="bookTitleModal">{props.book.titre}</h3>
                        <p className="bookAuthorModal">{props.book.auteur}</p>
                    </div>
                </>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" style={{textDecoration: 'none', color: 'rgb(107, 114, 128)' }} onClick = {props.onHide} >
                Poursuivre la navigation
            </Button>
            <Link to={`/cartshopping`}>
                <Button variant="warning" size="md">Voir le panier</Button>
            </Link>
        </Modal.Footer>
    </Modal>
  );
}

export default function BookDetails({cartItems, addToCart}) {
    let { book_sku } = useParams();
    const [bookDetails, setBookDetails] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [shoppingBook, setShoppingBook] = useState();
    const [image, setImage] = useState();
    const api = useApi();
    
    useEffect(() => {
        (async () => {
          const response = await api.get('/' + book_sku);
          if (response.ok) {
            setBookDetails(response.body);
            setImage(response.body.cover);
          }
          else {
            setBookDetails(null);
          }
        })();
    }, [book_sku, api]);
    
    function handleImageShower(image) {
        setImage(image);
    }
    
    if(bookDetails === undefined ){
        return (
            <Container>
                <Spinner animation="border" />
            </Container>
        );
    }else{
        return (
            <Container>
                <Row className="bookDetails">
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} md={12} className='bookPrincipal'>
                                {bookDetails.vendu === true?(
                                    <div className="ribbon-2">VENDU</div>
                                ):(
                                    <>
                                    </>
                                )}
                                <ImageShower image={image} />                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} md={4} className='bookSecondary'>
                                <div className='bookSecondaryContainer'>
                                    <Button variant="light" onClick={() => handleImageShower(bookDetails.pic_1)} className="buttonShower">
                                        <Image src={bookDetails.pic_1} className='bookSecondaryCover' />
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={4} md={4} className='bookSecondary'>
                                <div className='bookSecondaryContainer'>
                                    <Button variant="light" onClick={() => handleImageShower(bookDetails.pic_2)} className="buttonShower">
                                        <Image src={bookDetails.pic_2} className='bookSecondaryCover' />
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={4} md={4} className='bookSecondary'>
                                <div className='bookSecondaryContainer'>
                                    <Button variant="light" onClick={() => handleImageShower(bookDetails.cover)} className="buttonShower">
                                        <Image src={bookDetails.cover} className='bookSecondaryCover' />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={6} className='bookDetailContainerRight'>
                        <h2 className='bookDetailTitle'>{bookDetails.titre}</h2>
                        <p className='bookDetailAuthor'>De {bookDetails.auteur} (Auteur)</p>
                        <hr/>

                        <h6 className='bookDetailPrice'>{bookDetails.prix}$</h6>

                        <div className='bookDetailOther'>
                            <p>Langue: Français</p>
                            <p>Année d'édition: {bookDetails.date_edition}</p>
                            <p>Format: {bookDetails.format}</p>
                            <p>Poids : {bookDetails.poids} grammes</p>
                            <p>ISBN: {bookDetails.isbn}</p>
                        </div>

                        {cartItems.filter(e => e.sku === bookDetails.sku).length > 0?(
                            <Button variant="danger" size="lg" className="buttonBookDetail" disabled>Ajouter au panier</Button>
                        ):(
                            <Button onClick={() => {setShoppingBook(bookDetails); addToCart(bookDetails); setModalShow(true)}} variant="danger" size="lg" className="buttonBookDetail" >
                                Ajouter au panier
                            </Button>                    
                        )}
                
                        <ModalAddBook show={modalShow} book={shoppingBook} onHide={() => setModalShow(false)} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} className='bookDetailDescription'>
                        <h2 className="bookDetailDescriptionTitle">Description</h2>
                        <div className='bookDetailDescriptionBox'>
                            <p className='bookDetailDescriptionText'>{bookDetails.resume}</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} className='bookDetailRelated'>
                        <h2 className='bookDetailRelatedTitle'><span>Vous pourriez aussi aimer</span> </h2>
                    </Col>
                    
                    <BookRelated
                        key={bookDetails.id}
                        id={bookDetails.id}
                        sku={bookDetails.sku}
                        categorie={bookDetails.categorie.nom}
                        cartItems={cartItems}
                        addToCart={addToCart}
                    />
                </Row> 
            </Container>
        );
    }
}

import Body from "../components/body";
import BookDetails from "../components/bookDetails";


export default function BookPage({cartItems, addToCart}) {
    return (
        <Body>
            <BookDetails cartItems={cartItems} addToCart={addToCart} />
        </Body>
    );
}
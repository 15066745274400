import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Body from "../components/body";
import Book from "../components/books";
import Row from 'react-bootstrap/Row';


export default function SearchPage({addToFavoris, favorisItems, cartItems, addToCart}) {
    let { search } = useParams();
    //const [url, setUrl] = useState(`/api/search_books?search='${search}`);
    const [url, setUrl] = useState(`/search_books?search=JAPRISOT`);
    
    useEffect(() => {
        (async () => { 
          setUrl(`/search_books?search=${search}`);
        })();
    }, [search]);
    
    return (
        <Body>
            <section className='section'> 
                <Row>
                    <h6>résultats de recherche pour "{search}"</h6>                
                </Row>
                <Book addToFavoris={addToFavoris} favorisItems={favorisItems} url={url} cartItems={cartItems} addToCart={addToCart} />
            </section>
        </Body>
    );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


export default function CartShoppingInfo({cartItemsNumber, favorisItemsNumber}) {
  return (
    <>
        <form className="d-flex header-cart-shopping">
            <Link to={`/favoris`}>
                <Button variant="outline-secondary" className="header-button web-icon">
                    <FontAwesomeIcon icon={faHeartSolid} className="header-icon" />
                     Favoris
                    <span className="badge bg-warning text-white ms-1 rounded-pill">{favorisItemsNumber}</span>
                </Button>
            </Link>
            
            <Link to={`/cartshopping`}>
                <Button variant="outline-secondary" className="header-button web-icon">
                    <FontAwesomeIcon icon={faCartShopping} className="header-icon" />
                     Panier
                    <span className="badge bg-warning text-white ms-1 rounded-pill">{cartItemsNumber}</span>
                </Button>
            </Link>
            
            <Link to={`/favoris`}>
                <div className="mobile-icon-div">
                    <FontAwesomeIcon icon={faHeart} className="header-icon mobile-icon" />
                    <span className="badge bg-warning text-white ms-1 rounded-pill mobile-icon-badge">{favorisItemsNumber}</span>
                </div>
            </Link>
            
            <Link to={`/cartshopping`}>
                <div className="mobile-icon-div">
                    <FontAwesomeIcon icon={faCartShopping} className="header-icon mobile-icon" />
                    <span className="badge bg-warning text-white ms-1 rounded-pill mobile-icon-badge">{cartItemsNumber}</span>
                </div>
            </Link>
        </form>
    </>
  );
}



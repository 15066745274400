export default function Hero() {
    return (
        <section className='hero-section'>
            <div className="hero-container px-4 px-lg-5 my-1">
                <div className="text-center text-white">
                    <h1 className="hero-title display-4 fw-bolder">Les Petits Lecteurs</h1>
                </div>
                    <br/>
                    <p className="hero-text lead fw-normal text-white-50 mb-0">
                        Chez 'Les Petits Lecteurs', nous vendons des livres d'occasion parce que nous croyons en la lecture accessible et durable. 
                        Notre amour pour la lecture et notre préoccupation pour notre planète nous ont inspirés à créer un endroit où nos livres 
                        reprennent vie entre les mains d'autres 'Petits Lecteurs'. Ces livres ont été notre trésor pendant des années, et maintenant, 
                        c'est à vous de les chérir.
                    </p>
                    <br/>
                    <p className="hero-text lead fw-normal text-white-50 mb-0">Signé,</p>
                    <p className="hero-text lead fw-normal text-white-50 mb-0">Les 3 Petits Lecteurs</p>                
            </div>
        </section>
    );
}
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useApi } from '../contexts/ApiProvider';


export default function BookRelated({sku, categorie, cartItems, addToCart}) {
    const [booksRelated, setBooksRelated] = useState();
    const api = useApi();
    
    useEffect(() => {
        (async () => {
          const response = await api.get('/related_books/' + sku + '/' + categorie);
          if (response.ok) {
            setBooksRelated(response.body);
          }
          else {
            setBooksRelated(null);
          }
        })();
    }, [sku, categorie, api]);
    
    return (
        <>  
            {booksRelated === undefined ?
                <Spinner animation="border" />
            :
            booksRelated.map(book => {
                return(
                    <Col xs={6} md={4} lg={3} key={book.id}>
                        <div className="book">
                            <div className='bookCard'>
                                <div className='bookCardImage'>
                                    <Image src={book.cover}  className="bookImage" />
                                </div>
                                <div className="bookButtondiv">                                
                                    {cartItems.filter(e => e.sku === book.sku).length > 0?(
                                        <Button variant="danger" size="sm" className="bookButton" disabled>Ajouter au panier</Button>
                                    ):(
                                        <Button onClick={() => addToCart(book)} variant="danger" size="sm" className="bookButton">Ajouter au panier</Button>                    
                                    )}
                                </div>
                                <div className="bookInfo">
                                    <div>
                                        <h3 className="bookTitle">
                                            <Link to={`/book/${book.sku}`} style={{ textDecoration: 'none', color: 'rgb(55 65 81)', fontWeight: 700, fontSize: '0.925rem' }}>
                                                <span aria-hidden="true" className="bookTitleLink"></span>
                                                {book.titre}
                                            </Link>
                                        </h3>
                                        <p className="bookAuthor">{book.auteur}</p>
                                    </div>
                                    <p className="bookPrice">${book.prix}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                );
            })}
        </>
    );
}



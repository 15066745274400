import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import bookPicture from '../images/world_book_fun.jpg';

function ModalAddBook(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="modal-title">
                Ce livre a bien été ajouté à votre panier
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodyModal">
            {props.book === undefined ?(
                <>
                    <div className='bookCardImageModal'>
                        <Image src={bookPicture}  className="bookImageModal" />
                    </div>
                    <div className="bookInfoModal">
                        <h3 className="bookTitleModal">Title</h3>
                        <p className="bookAuthorModal">Author</p>
                    </div>
                </>
            ):(
                <>                            
                    <div className='bookCardImageModal'>
                        <Image src={props.book.cover}  className="bookImageModal" />
                    </div>
                    <div className="bookInfoModal">
                        <h3 className="bookTitleModal">{props.book.titre}</h3>
                        <p className="bookAuthorModal">{props.book.auteur}</p>
                    </div>
                </>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Poursuivre la navigation</Button>
            <Link to={`/cartshopping`}>
                <Button variant="warning" size="md">Voir le panier</Button>
            </Link>
        </Modal.Footer>
    </Modal>
  );
}

export default function Favoris({favorisItems, favorisItemsNumber, cartItems, addToCart, empty, remove}) {
    const [modalShow, setModalShow] = useState(false);
    const [shoppingBook, setShoppingBook] = useState();
         
    return (
        <>  
            <Row>
                <Col xs={12} md={12} className='favoris'>
                    <h2 className='favorisTitle'><span>Mes favoris</span></h2>
                </Col>
            </Row>
            
            <Row className="subcontainer">
                <div className="favorisEmpty">
                    <p>({favorisItemsNumber} livres)</p>
                    
                    <Button variant="link" onClick={empty} className="favorisButtonEmpty">
                        Supprimer tous les favoris
                    </Button>
                </div>
                {favorisItems === undefined ?
                    <Spinner animation="border" />
                :
                favorisItems.map(item => {
                    return(                        
                        <Col xs={12} md={12} className="favorisMdScreen" key={item.id} >                             
                            <div className="favorisItem">
                                <div className="favorisLeft">
                                    <Image src={item.cover} className="favorisItemImage" />
                                    <div className="favorisItemInfo">
                                        <div>
                                            <h3 className="favorisItemTitle">{item.titre}</h3>
                                            <p className="favorisItemAuthor">{item.auteur}</p>
                                        </div>
                                        <div className="favorisItemPrice">
                                            <h4 className="favorisItemPriceLibelle">{item.prix - item.remise}$</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="favorisRigth">                            
                                    <div className="favorisItemDelete">
                                        <a onClick={() => remove(item)}>
                                            <FontAwesomeIcon icon={faTrashCan} />  
                                        </a>
                                    </div>
                                    <div className="favorisItemPrice">                                             
                                        {cartItems.filter(e => e.sku === item.sku).length > 0?(
                                            <Button className="favorisButtonAdd" size="md" disabled>Ajouter au panier</Button>
                                        ):(
                                            <Button onClick={() => {setShoppingBook(item); addToCart(item); setModalShow(true); remove(item)}} className="favorisButtonAdd" size="md">
                                                Ajouter au panier
                                            </Button>                    
                                        )}

                                        <ModalAddBook show={modalShow} book={shoppingBook} onHide={() => setModalShow(false)} />
                                    </div>
                                </div>  
                            </div>
                        </Col>
                    );
                })} 
                
                {favorisItems === undefined ?
                    <Spinner animation="border" />
                :
                favorisItems.map(item => {
                    return(               
                        <Col xs={12} md={12} className="favorisXsScreen" key={item.id} >
                            <div className='favorisItem'>
                                <Image src={item.cover} className="favorisItemImage" />                            
                                <div className="favorisItemInfo">
                                    <div className="favorisItemTop">
                                        <h5 className="favorisItemTitle">{item.titre}</h5>
                                        <p className="favorisItemAuthor">{item.auteur}</p>
                                    </div>
                                    <div className="favorisItemBottom">
                                        <h4 className="favorisItemPrice">{item.prix - item.remise}$</h4>
                                        <div className="favorisItemDelete">
                                            <a onClick={() => remove(item)}>
                                                <FontAwesomeIcon icon={faTrashCan} />  
                                            </a>
                                        </div>                                           
                                        <div className="favorisAddToCartBlock">
                                            {cartItems.filter(e => e.sku === item.sku).length > 0?(
                                                <FontAwesomeIcon icon={faCartShopping} className="favorisAddToCartDisabled" />
                                            ):(
                                                <a onClick={() => {setShoppingBook(item); addToCart(item); setModalShow(true); remove(item)}}>
                                                    <FontAwesomeIcon icon={faCartShopping} className="favorisAddToCart" />
                                                </a>                    
                                            )}
                                            <ModalAddBook show={modalShow} book={shoppingBook} onHide={() => setModalShow(false)} />                                               
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </Col>
                    );
                })} 
            </Row>
        
        </>
    )
}



import Body from "../components/body";
import CartShopping from "../components/cartShopping";


export default function CartShoppingPage({cartItems, cartItemsNumber, empty, remove, total_price}) {
    return (
        <Body>
            <CartShopping cartItems={cartItems} cartItemsNumber={cartItemsNumber} empty={empty} remove={remove} total_price={total_price} />
        </Body>
    );
}

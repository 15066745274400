import React from 'react';
import { useState } from 'react';
import Body from "../components/body";
import Hero from "../components/hero";
import Book from "../components/books";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default function HomePage({addToFavoris, favorisItems, cartItems, addToCart}) {
    const [url, setUrl] = useState('/recent_books');
    
    return (
        <Body>
            <Hero />
            <ErrorBoundary fallback={<p>Something went wrong</p>}>
                <Book addToFavoris={addToFavoris} 
                      favorisItems={favorisItems} 
                      url={url} 
                      setUrl={setUrl} 
                      cartItems={cartItems} 
                      addToCart={addToCart} />
            </ErrorBoundary>
        </Body>
    );
}

import logo from '../images/logo.png';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer() {
    return (
        <Container>
            <footer className="dark:bg-gray-800 footer-section">
                <div className="footerInner">
                    <hr className="footerHr sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center">                        
                            <a href="#" className="footerLogo">
                                <Image src={logo} className="foooterImage sm:h-9" width={240}/>
                            </a>
                        </Col>
                        <Col xs={12} className="d-flex justify-content-center">
                            <span className="footerCopyright dark:text-gray-400">© 2023 Les Petits Lecteurs. All Rights Reserved.</span>
                        </Col>
                    </Row>
                </div>
            </footer>
        </Container>
    );
}

import Container from 'react-bootstrap/Container';
import FlashMessage from './FlashMessage';

export default function Body({ children }) {
    return (
      <Container>
        <FlashMessage />
        {children}
      </Container>
    );
}
import Body from "../components/body";
import Favoris from "../components/favoris";


export default function FavorisPage({favorisItems, favorisItemsNumber, cartItems, addToCart, empty, remove}) {
    return (
        <Body>
            <Favoris 
                favorisItems={favorisItems} 
                favorisItemsNumber={favorisItemsNumber} 
                cartItems={cartItems} 
                addToCart={addToCart} 
                empty={empty} 
                remove={remove} />
        </Body>
    );
}

import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
//import Airtel from '../images/Airtel-money.jpg';
import Vodacom from '../images/mpesa.png';
//import Orange from '../images/orange-money.jpg';
//import Africell from '../images/afrimoney.jpeg';
import Blank from '../images/blank.jpg';
import { Formik, Form as MyForm, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { useApi } from '../contexts/ApiProvider';

// const resend = new Resend('re_PBk3He9u_8syFrq5HZL9BtnM9BVg5K6kR');
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <Form.Group className="mb-3" controlId={props.controlid}>
        <Form.Label>{label}</Form.Label>
        <Form.Control {...field} {...props} />
      </Form.Group>
      
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MySelect = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <Form.Group className="mb-3" controlId={props.controlid}>
        <Form.Label>{label}</Form.Label>
        <Form.Select {...field} {...props} />
      </Form.Group>
      {meta.touched && meta.error ? (
              <div>{meta.error}</div>
      ) : null}
    </>
  );
};

function ModalThanks(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="modal-title">
                Panier validé avec succès
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodyModal">
            <h3>Merci pour votre achat !</h3>
            <br/>
            <p> 
                Nous procédons à quelques vérifications et vous contacterons 
                sous peu pour le retrait de vos(votre) livre(s).
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Link to={`/`}>
                <Button variant="warning" size="md" onClick = {props.onHide} >Fermer</Button>
            </Link>
        </Modal.Footer>
    </Modal>
  );
}


function ModalValidateCart(props) {
    const api = useApi();
  return (
    <Modal size="lg" {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
            <Modal.Title id="modal-title">
                Validation du panier
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodyModal">
            <Row>
                <Col xs={1} md={1}>
                    <Image src={Blank} className="shoppingItemImage" />
                </Col>
                <Col xs={10} md={10} className="text-center">
                    <Image src={Vodacom} className="shoppingItemImage" />
                </Col>
                <Col xs={1} md={1}> 
                    <Image src={Blank} className="shoppingItemImage" />
                </Col>
            </Row>
            <Row>
                <Col> </Col>
                <Col xs={10} md={10} className="text-center">
                    <h6>+243 837 20 84 59</h6>
                </Col>
                <Col> </Col>
            </Row>
            
            <br/>
            <hr/>
            
            <Formik
                initialValues={{
                  transaction: "",
                  nom: "",
                  telephone: "",
                  transactionType: "" // added for our select
                }}
                validationSchema={Yup.object({
                  transaction: Yup.string()
                    .max(20, "Doit contenir 20 caractères ou moins"),
                  nom: Yup.string()
                    .max(30, "Doit contenir 30 caractères ou moins")
                    .required("Required"),
                  telephone: Yup.string()
                    .max(10, "Doit contenir 10 caractères ou moins")
                    .required("Required"),
                  transactionType: Yup.string()
                    // specify the set of valid values for job type
                    // @see http://bit.ly/yup-mixed-oneOf
                    .oneOf(
                      ["mpesa", "cash"],
                      "Type de transaction invalide"
                    )
                    .required("Required")
                })}
                onSubmit={async(values, { setSubmitting, resetForm }) => {
                    const data = await api.post('/cart/', {
                        transaction_type: values.transactionType,
                        transaction: values.transaction,
                        nom: values.nom,
                        telephone: values.telephone,
                        books: props.books
                    });
                    if (data.ok){
                        props.onHide();
                        props.emptyCart();
                        props.onShowModalThanks();
                    }
                    else{
                        console.log(data);
                    }
                    //const content = await data.json();
                    setSubmitting(false);
                    resetForm();
                }}
            >
                <MyForm>
                  <MySelect label="Type Transaction" name="transactionType" controlid="formGroupSelect" >
                    <option value="">Sélectionnez le type de transaction</option>
                    <option value="mpesa">Vodacom MPESA</option>
                    <option value="cash">Paiement cash au retrait</option>
                  </MySelect>
                  <MyTextInput
                    label="Numéro de transaction"
                    name="transaction"
                    type="text"
                    placeholder="Numéro de transaction généré par MPESA"
                    controlid="formGroupTransaction"
                  />
                  <MyTextInput
                    label="Nom"
                    name="nom"
                    type="text"
                    placeholder="Votre Nom"
                    controlid="formGroupNom"
                  />
                  <MyTextInput
                    label="Téléphone"
                    name="telephone"
                    type="text"
                    placeholder="Votre numéro de téléphone"
                    controlid="formGroupTel"
                  />
                 
                  <br/>
                  <Button variant="danger" className="buttonShoppingValidate" type='submit' >
                    Envoyer
                  </Button>
                </MyForm>
            </Formik>
            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" onClick={props.onHide} style={{textDecoration: 'none', color: 'rgb(55 65 81)', fontWeight: 700, fontSize: '1.15rem' }} >
                Fermer
            </Button>
        </Modal.Footer>
    </Modal>
  );
}

export default function CartShopping({cartItems, cartItemsNumber, empty, remove, total_price}) {
    const [modalShow, setModalShow] = useState(false);
    const [modalThanksShow, setModalThanksShow] = useState(false);
    
    let books_id = cartItems.map(item => item.id);

    return (
        <>
            <Row>
                <div className="shoppingConditions">
                    <h2 className="shoppingConditionsTitle">Conditions générales de vente</h2>
                    <p className="shoppingConditionsText">Pour valider votre commande, veuillez effectuer le paiement du montant de votre panier via mobile money. 
                    Une fois la transaction confirmée, vous serez contacté dans les 24 heures pour organiser le retrait de votre commande.</p>

                    <p className="shoppingConditionsText">Veuillez noter que les retraits sont organisés exclusivement dans la ville de Lubumbashi. 
                    Les marchandises vendues ne sont pas reprises, mais peuvent être échangées lors du retrait.</p>
                </div>
            </Row>
            
            <Row>
                <Col xs={12} md={12} className='cartShopping'>
                    <h2 className='cartShoppingTitle'><span>Mon panier</span></h2>
                </Col>
            </Row>
            
            <Row className="subcontainer">
                <div className="shoppingEmpty">
                    <p className="shoppingItemsNumber">({cartItemsNumber} livres)</p>
                    
                    <Button variant="link" onClick={empty} className="shoppingButtonEmpty">
                        Vider le panier
                    </Button>
                </div>
                {cartItems === undefined ?
                    <Spinner animation="border" />
                :
                cartItems.map(item => {
                    return( 
                        <Col xs={12} md={12} className="shoppingMdScreen" key={item.id} >                             
                            <div className="shoppingItem">
                                <div className="shoppingLeft">
                                    <Image src={item.cover} className="shoppingItemImage" />
                                    <div className="shoppingItemInfo">
                                        <div>
                                            <h3 className="shoppingItemTitle">{item.titre}</h3>
                                            <p className="shoppingItemAuthor">{item.auteur}</p>
                                        </div>
                                        <div className="shoppingItemQty">
                                            <p className="shoppingItemQtyLibelle">Quantité: 1</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shoppingRigth">                            
                                    <div className="shoppingItemDelete">
                                        <a onClick={() => remove(item)}>
                                            <FontAwesomeIcon icon={faTrashCan} />  
                                        </a>
                                    </div>
                                    <div className="shoppingItemPrice">                                        
                                        <h4 className="shoppingItemPriceLibelle">{item.prix - item.remise}$</h4>
                                    </div>
                                </div>  
                            </div>
                        </Col>
                    );
                })} 
                
                {cartItems === undefined ?
                    <Spinner animation="border" />
                :
                cartItems.map(item => {
                    return(                
                        <Col xs={12} md={12} className="shoppingXsScreen" key={item.id} >
                            <div className='shoppingItem'>
                                <Image src={item.cover} className="shoppingItemImage" />                            
                                <div className="shoppingItemInfo">
                                    <div className="shoppingItemTop">
                                        <h5 className="shoppingItemTitle">{item.titre}</h5>
                                        <p className="shoppingItemAuthor">{item.auteur}</p>
                                    </div>
                                    <div className="shoppingItemBottom">
                                        <div className="shoppingItemDelete">
                                            <a onClick={() => remove(item)}>
                                                <FontAwesomeIcon icon={faTrashCan} />  
                                            </a>
                                        </div>
                                        <p className="shoppingItemQty">x1</p>
                                        <h4 className="shoppingItemPrice">{item.prix - item.remise}$</h4>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </Col>
                    );
                })} 
                
                <br/>
                
                <Col xs={12} md={12} className="shoppingMdScreen"> 
                    <div className="shoppingTotal">
                        <h3>Total :</h3>
                        <h3>{total_price}$</h3>
                    </div>
                </Col>
                <Col xs={12} md={12} className="shoppingXsScreen"> 
                    <div className="shoppingTotal">
                        <h3>Total :</h3>
                        <h3>{total_price}$</h3>
                    </div>
                    <hr/>
                </Col>
                <br/>
                <Col xs={12} md={12} className="text-center">
                    <Button onClick={() => {setModalShow(true)}} variant="danger" size="lg" className="buttonShoppingValidate" >
                        Valider le panier
                    </Button>
                </Col>
            </Row>
            <ModalValidateCart show={modalShow} onHide={() => setModalShow(false)} books={books_id} emptyCart={empty} onShowModalThanks={() => {setModalThanksShow(true)}} />
            <ModalThanks show={modalThanksShow} onHide={() => setModalThanksShow(false)} />
        </>
    )
}
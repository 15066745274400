import React from 'react';
import { useState, useEffect } from 'react';
import logo from '../images/logo1.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import CartShoppingInfo from './cartShoppingInfo';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowDownWideShort, faCaretDown, faBars} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'; 
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useApi } from '../contexts/ApiProvider';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    className="categorie-dropdown"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faArrowDownWideShort} className="categorie-icon" />
    {children}
    <FontAwesomeIcon icon={faCaretDown} className="categorie-icon" />
  </Button>
));

export default function Header({cartItemsNumber, favorisItemsNumber}) {
    const [search, setSearch] = useState('');
    const [categories, setCategories] = useState();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const api = useApi();
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    useEffect(() => {
        (async () => {
          const response = await api.get('/categories');
          if (response.ok) {
            setCategories(response.body);
          }
          else {
            setCategories(null);
          }
        })();
    }, [api]);
  
  function handleSubmit(e) {
    e.preventDefault();
    navigate( `/recherche/${search}`, { replace: true });
  }
  
  return (
    <section className="header-section">
        <div className="categorie-top-section">
            <Container>
                <div className="content-top-section">
                    <div className="hidden-top-section"></div>
                    <h6 className="title-top-section">LES PETITS LECTEURS</h6>
                    <div className="contact-top-section">
                        {/*<Image src={whatsapp} className="image-top-section" alt="Landwind Logo" width="35"*/}
                        <span className="top-whatsapp"><FontAwesomeIcon icon={faWhatsapp} className="top-icon" /></span>
                        <h6 className="number-top-section">
                            <a href="https://wa.me/243837208459" style={{display:'inline-block', textDecoration: 'none', color: 'white'}}>
                                +243 837 20 84 59
                            </a>
                        </h6>
                        <span></span>
                        <span className="top-mail"><FontAwesomeIcon icon={faEnvelope} className="top-icon" /></span>
                        <h6 className="number-top-section">
                            <a href="mailto:lespetitslecteurs.lshi@gmail.com" style={{display:'inline-block', textDecoration: 'none', color: 'white'}} >
                                lespetitslecteurs.lshi@gmail.com
                            </a>
                        </h6>
                    </div>
                </div>
            </Container>
        </div>
        
        <Navbar expand="lg" sticky="top" className="Header">
            <Container>                
                <Navbar.Brand className="header-brand">
                    <Link to={`/`} style={{ textDecoration: 'none' }}>
                        <Image src={logo}  className="d-inline-block header-image" alt="Landwind Logo" width="120" height="120" />
                    </Link>
                    <Link to={`/`} style={{ textDecoration: 'none' }}>
                        <span className="site-title">Les Petits Lecteurs</span>
                    </Link>
                </Navbar.Brand>
                <FontAwesomeIcon icon={faBars} className="d-lg-none menu-icon" onClick={handleShow} />
                <Offcanvas show={show} onHide={handleClose} responsive="lg">
                    <Offcanvas.Header closeButton>
                        <div className="offcanvas-header">
                            <Image src={logo}  className="d-inline-block" alt="Landwind Logo" width="160" height="160" />
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DropdownButton
                            as={ButtonGroup}
                            key={`end`}
                            id={`dropdown-button-drop-end`}
                            drop={`end`}
                            variant="link"
                            title={` Catégories `}
                            size="lg"
                            className="dropdown-offcanvas"
                        >
                            {categories === undefined ?
                                <span>Aucune catégorie</span>
                            :
                                <>
                                    {categories === null ?
                                        <Dropdown.Item>Aucune categorie</Dropdown.Item> 
                                    :
                                     categories.map(categorie => {
                                        return( 
                                            <Dropdown.Item key={categorie.id} href={`/categorie/${categorie.nom}`} eventKey={categorie.id}>{categorie.nom}</Dropdown.Item> 
                                        );
                                     })}
                                </>
                            }
                        </DropdownButton>
                        
                    </Offcanvas.Body>
                </Offcanvas>
                
                <Form className="d-flex header-form" onSubmit={handleSubmit}>
                    <Form.Control
                      type="search"
                      placeholder="Rechercher un livre"
                      className="me-1"
                      aria-label="Search"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                    <Button onClick={handleSubmit} variant="outline-primary">
                        <FontAwesomeIcon icon={faSearch} className="header-icon" />
                    </Button>                        
                </Form>
                
                <CartShoppingInfo cartItemsNumber={cartItemsNumber} favorisItemsNumber={favorisItemsNumber} />
            </Container>
        </Navbar>
        <div className="categorie-section">
            <Container className="d-flex categories-menu">
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                      Catégories
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {categories === undefined ?
                        <span>Aucune catégorie</span>
                      :
                        <>
                            {categories === null ?
                                <Dropdown.Item>Aucune categorie</Dropdown.Item> 
                            :
                             categories.map(categorie => {
                                return( 
                                    <Dropdown.Item key={categorie.id} href={`/categorie/${categorie.nom}`} eventKey={categorie.id}>{categorie.nom}</Dropdown.Item> 
                                );
                             })}
                        </>
                      }
                    </Dropdown.Menu>
                </Dropdown>
            </Container>
        </div>
    </section>
  );
}
import { createContext, useContext, useCallback, useMemo  } from 'react';
import BookApiClient from '../BookApiClient';
import { useFlash } from './FlashProvider';

export const ApiContext = createContext();

export default function ApiProvider({ children }) {
  const flash = useFlash();

  const onError = useCallback(() => {
    flash("Une erreur innatendue s'est produite. Prière de réessayer.", 'danger');
  }, [flash]);
  
  const api = useMemo(() => new BookApiClient(onError), [onError]);

  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  return useContext(ApiContext);
}



import { useState, useEffect, useCallback, memo } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons';
import bookPicture from '../images/world_book_fun.jpg';
import BookPagination from './bookPagination';
import { useApi } from '../contexts/ApiProvider';


function ModalAddBook(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="modal-title">
                Ce livre a bien été ajouté à votre panier
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodyModal">
            {props.book === undefined ?(
                <>
                    <div className='bookCardImageModal'>
                        <Image src={bookPicture}  className="bookImageModal" />
                    </div>
                    <div className="bookInfoModal">
                        <h3 className="bookTitleModal">Title</h3>
                        <p className="bookAuthorModal">Author</p>
                    </div>
                </>
            ):(
                <>                            
                    <div className='bookCardImageModal'>
                        <Image src={props.book.cover}  className="bookImageModal" />
                    </div>
                    <div className="bookInfoModal">
                        <h3 className="bookTitleModal">{props.book.titre}</h3>
                        <p className="bookAuthorModal">{props.book.auteur}</p>
                    </div>
                </>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" style={{textDecoration: 'none', color: 'rgb(107, 114, 128)' }} onClick = {props.onHide} >
                Poursuivre la navigation
            </Button>
            <Link to={`/cartshopping`}>
                <Button variant="warning" size="md">Voir le panier</Button>
            </Link>
        </Modal.Footer>
    </Modal>
  );
}

function ModalFavoris(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="modal-title">
                Ce livre a bien été ajouté à vos favoris
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodyModal">
            <div className="bookModal">
                <div className='bookCardModal'>
                    {props.book === undefined ?(
                        <>
                            <div className='bookCardImageModal'>
                                <Image src={bookPicture}  className="bookImageModal" />
                            </div>
                            <div className="bookInfoModal">
                                <h3 className="bookTitleModal">Title</h3>
                                <p className="bookAuthorModal">Author</p>
                            </div>
                        </>
                    ):(
                        <>                            
                            <div className='bookCardImageModal'>
                                <Image src={props.book.cover}  className="bookImageModal" />
                            </div>
                            <div className="bookInfoModal">
                                <h3 className="bookTitleModal">{props.book.titre}</h3>
                                <p className="bookAuthorModal">{props.book.auteur}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" style={{textDecoration: 'none', color: 'rgb(107, 114, 128)' }} onClick = {props.onHide}>
                Poursuivre la navigation
            </Button>
            <Link to={`/favoris`}>
                <Button variant="warning" size="md">Voir les favoris</Button>
            </Link>
        </Modal.Footer>
    </Modal>
  );
}

export default memo(function Book({addToFavoris, url, setUrl, favorisItems, cartItems, addToCart}){
    const [books, setBooks] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [modalShoppingShow, setModalShoppingShow] = useState(false);
    const [shoppingBook, setShoppingBook] = useState();
    const [favorisBook, setFavorisBook] = useState();
    const [nextUrl, setNextUrl] = useState(null);
    const [prevUrl, setPrevUrl] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const api = useApi();
    
    function calculatePageNumber(count) {
        const quotient = Math.floor(count/30);
        const remainder = count % 30;
        let total_pages = quotient;
        if(remainder > 0){
            total_pages += 1;
        }
        return total_pages;
    }
    
    useEffect(() => {
        (async () => {
          const response = await api.get(url);
          if (response.ok) {
            setBooks(response.body.results);
            setNextUrl(response.body.next);
            setPrevUrl(response.body.previous);
            setCurrentPage(response.body.current);
            let total_pages = calculatePageNumber(response.body.count);
            setPageCount(total_pages);
          }
          else {
            setBooks(null);
          }
        })();
    }, [api, url]);
    
    const handleNextUrl = useCallback(() => {
        setUrl(nextUrl);
    }, [nextUrl, setUrl]);

    const handlePrevUrl = useCallback(() => {
        setUrl(prevUrl);
    }, [prevUrl, setUrl]);
    
    const handleSelectedUrl = useCallback((page) => {
        let pageToFind = "?page=" + page;
        setUrl(pageToFind);
    }, [setUrl]); 
    
    function testId(sku, e){ 
        e.preventDefault();
        e.stopPropagation();
        //console.log('ID***');
        //console.log(e.currentTarget.id);
        if(e.currentTarget.id !== "mainButton"){
            //console.log('FAVORIS BUTTON');
            // console.log(e.currentTarget.id);
        }else{
            //console.log('MAIN BUTTON');
            //console.log(sku);
            navigate(`/book/${sku}`, { replace: true });
        }
    }
    
    function handleVendu(e) {
        console.log('*****************************************');
    }

    return (
        <Container>
            <section className='section'>                
                <Row className='newestBooksSection'>
                    <Col xs={12} md={12} >
                        <Row>
                            {books === undefined ?
                                <Spinner animation="border" />
                            :
                              <>
                                {books === null ?
                                    <p>Aucun livre trouve</p>
                                :
                                  books.map(book => {
                                    return( 
                                        <Col xs={6} md={4} lg={3} xl={2} key={book.id}>                                             
                                            <div onClick={(book.vendu === true? handleVendu : (e) => {testId(book.sku, e);})} id="mainButton" variant="link" style={{textDecoration: 'none' }} >                                                
                                                <div className="book">
                                                    <div className='bookCard'>
                                                        <div className='bookCardImage'>
                                                            {book.vendu === true?(
                                                                <div className="ribbon ribbon-top-right"><span>VENDU</span></div>
                                                            ):(
                                                            <>
                                                               {favorisItems.filter(e => e.sku === book.sku).length > 0?(
                                                                    <FontAwesomeIcon icon={faSolidHeart} className="buttonAddToFavoris" />
                                                                ):(
                                                                    <FontAwesomeIcon icon={faHeart} id="favButton" className="buttonAddToFavoris" onClick={(e) => {setFavorisBook(book); setModalShow(true); addToFavoris(book); testId(book.sku, e);}}/>   
                                                                )} 
                                                            </>
                                                            )}                                                   
                                                                                                                           
                                                                <Image src={book.cover}  className="bookImage" />                                                                
                                                        </div>
                                                        <div className="bookButtondiv">
                                                            {cartItems.filter(e => e.sku === book.sku).length > 0 || book.vendu === true?(
                                                                <Button variant="danger" size="sm" className="bookButton" disabled>Ajouter au panier</Button>
                                                            ):(
                                                                <Button onClick={(e) => {testId(book.sku, e); setShoppingBook(book); addToCart(book); setModalShoppingShow(true);}} id="insideButton" variant="danger" size="sm" className="bookButton">
                                                                    Ajouter au panier
                                                                </Button>                    
                                                            )}
                                                        </div>
                                                        <div className="bookInfo">
                                                            <div>                                                            
                                                                <h3 className="bookTitle">
                                                                    <span aria-hidden="true" className="bookTitleLink"></span>
                                                                    {book.titre}                                                           
                                                                </h3>                                                            
                                                                <p className="bookAuthor">{book.auteur}</p>
                                                            </div>
                                                            {book.remise > 0?(
                                                                <div>
                                                                    <p className="bookPrice" style={{textDecoration: 'line-through black 2px', marginBottom: '2px'}}>
                                                                        ${book.prix}
                                                                    </p> 
                                                                    <p className="bookPrice" style={{marginBottom: '0'}}>${book.prix - book.remise}</p>
                                                                </div>    
                                                            ):(
                                                                <p className="bookPrice">${book.prix}</p>  
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}  
                                <ModalFavoris show={modalShow} book={favorisBook} onHide={() => setModalShow(false)} />
                                <ModalAddBook show={modalShoppingShow} book={shoppingBook} onHide={() => setModalShoppingShow(false)} />
                              </>
                            }
                        </Row>                                               
                    </Col>
                    <br/>
                    <Col xs={12} md={12} className="d-flex justify-content-center" >
                        <BookPagination
                            prevUrl={prevUrl}
                            nextUrl={nextUrl}
                            handleNextUrl={handleNextUrl}
                            handlePrevUrl={handlePrevUrl}
                            pageCount={pageCount}
                            currentPage={currentPage}
                            handleSelectedUrl={handleSelectedUrl}
                        /> 
                    </Col>
                </Row>
            </section>
        </Container>
    );
});
    